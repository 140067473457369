import laptop from "../assets/laptop.webp";


export const testimonialsData = [
  {
    image: laptop,
    review:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, senectus vitae aptent pulvinar.",
    name: 'LOREM',
    status : 'LOREM'
  },
  {
    image: laptop,
    review: 'Lorem ipsum dolor sit amet consectetur adipiscing elit, senectus vitae aptent pulvinar.',
    name: 'LOREM',
    status: 'LOREM'
  },
  {
    image : laptop,
    review:'Lorem ipsum dolor sit amet consectetur adipiscing elit, senectus vitae aptent pulvinar.',
    name: 'LOREM',
    status: "LOREM"
  }
];
