import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rwureuc",
        "template_h08f5xa",
        form.current,
        "-u6bIr7idZyFg7FZK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="Join">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Cosas</span>
          <span>Increibles</span>
        </div>
        <div>
          <span>Para</span>
          <span className="stroke-text">Ti</span>
        </div>
        <div>
          <span>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut, sint? Corporis eius placeat beatae ut odit doloribus, maxime perspiciatis. Quos, sunt expedita. Accusamus itaque quia odit esse, cumque est nemo.
          </span>
        </div>
      </div>
      <div className="right-j">
        <form
          ref={form}
          action=""
          className="email-container"
          onSubmit={sendEmail}
        >
          <label htmlFor="contact">Contacto</label>
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            name="user_name"
            placeholder="Ingresa tu nombre"
          />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="user_email"
            placeholder="Enter your Email address"
          />
          <label htmlFor="message">Mensaje:</label>
          <textarea
            name="message"
            id=""
            cols="30"
            rows="10"
            placeholder="Escribe tu mensaje"
          ></textarea>
          <button className="btn btn-j">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
