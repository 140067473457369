import React from "react";
import Header from "./Header/Header";
import "./Hero.css";
import Plantilla from "../../assets/plantilla1.jpg";
import Adidaselon from "../../assets/adidaselon.gif";
import NumberCounter from 'number-counter'

import { motion } from "framer-motion";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile? "130px": "157px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>Plantilla multi-proposito</span>
        </div>

        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Crea</span>
            <span> Tu</span>
          </div>
          <div>
            <span>Sitio Web</span>
          </div>
          <div>
            <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </span>
          </div>
        </div>

        {/* figures */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay='4' preFix="+"/>  
            </span>
            <span>Plantillas</span>
          </div>
          <div>
            <span>
            <NumberCounter end={978} start={800} delay='4' preFix="+"/>
            </span>
            <span>Nuevos estilos</span>
          </div>
          <div>
            <span>
            <NumberCounter end={10} start={0} delay='4' preFix="+"/>
            </span>
            <span>Idiomas</span>
          </div>
        </div>

        {/* hero button */}
        <div className="hero-buttons">
          <button className="btn">Servicios</button>
          <button className="btn">Nosotros</button>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Unete</button>

        {/* hero images */}
        <img src={Plantilla} alt="" className="hero-image" />
        <motion.img
          initial={{right: '1rem'}}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={Adidaselon} alt="" className="hero-image-back" />
      </div>
    </div>
  );
};

export default Hero;
